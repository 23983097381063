export default {
    namespaced: true,
    state: {
        apiMenus: []
    },
    getters: {
        totalMenus: state => {
            return state.apiMenus.length
        },

        extractChildren: (state, getters) => (parent) => {
            const childrenMenu = []
            let x = -1

            for(let i = 0; i < parent.children.length; i++){
                let isComponent = typeof(parent.children[i].isComponent) === 'string'
                                ? Boolean(parseInt(parent.children[i].isComponent))
                                : (typeof(parent.children[i].isComponent) === 'number' ? Boolean(parent.children[i].isComponent) : parent.children[i].isComponent)

                if(!isComponent){
                    x += 1
            
                    if(parent.children[i].children.length === 0){
                        let isSection = typeof(parent.children[i].isSection) === 'string'
                                        ? Boolean(parseInt(parent.children[i].isSection))
                                        : (typeof(parent.children[i].isSection) === 'number' ? Boolean(parent.children[i].isSection) : parent.children[i].isSection)

                        if(isSection){
                            childrenMenu[x] = { header: parent.children[i].title }
                        }
                        else{
                            childrenMenu[x] = parent.children[i].webIcon !== null
                                             ? { title: parent.children[i].title, route: parent.children[i].webRoute, icon: parent.children[i].webIcon }
                                             : { title: parent.children[i].title, route: parent.children[i].webRoute }
                        }
                    }
                    else{
                        const children = getters.extractChildren(parent.children[i])

                        let isSection = typeof(parent.children[i].isSection) === 'string'
                                        ? Boolean(parseInt(parent.children[i].isSection))
                                        : (typeof(parent.children[i].isSection) === 'number' ? Boolean(parent.children[i].isSection) : parent.children[i].isSection)

                        if(children.length > 0){
                            if(isSection){
                                childrenMenu[x] = { header: parent.children[i].title }
                                for(let c = 0; c < children.length; c++){
                                    x += 1
                                    childrenMenu[x] = children[c]
                                }
                            }
                            else{
                                childrenMenu[x] = parent.children[i].webIcon !== null
                                                ? { title: parent.children[i].title, route: parent.children[i].webRoute, icon: parent.children[i].webIcon, children }
                                                : { title: parent.children[i].title, route: parent.children[i].webRoute, children }
                            }
                        }
                        else{
                            let isSection = typeof(parent.children[i].isSection) === 'string'
                                        ? Boolean(parseInt(parent.children[i].isSection))
                                        : (typeof(parent.children[i].isSection) === 'number' ? Boolean(parent.children[i].isSection) : parent.children[i].isSection)

                            if(isSection){
                                childrenMenu[x] = { header: parent.children[i].title }
                            }
                            else{
                                childrenMenu[x] = parent.children[i].webIcon !== null
                                                ? { title: parent.children[i].title, route: parent.children[i].webRoute, icon: parent.children[i].webIcon }
                                                : { title: parent.children[i].title, route: parent.children[i].webRoute }
                            }
                        }
                    }
                }
            }

            return childrenMenu
        },
        
        verticalNavMenuItems: (state, getters) => {
            const apiMenus = state.apiMenus
            const navigations = []
            let index = -1

            for(let i = 0; i < apiMenus.length; i++){
                let isComponent = typeof(apiMenus[i].isComponent) === 'string'
                                    ? Boolean(parseInt(apiMenus[i].isComponent))
                                    : (typeof(apiMenus[i].isComponent) === 'number' ? Boolean(apiMenus[i].isComponent) : apiMenus[i].isComponent)

                if(!isComponent){
                    index += 1

                    if(apiMenus[i].children.length === 0){
                        let isSection = typeof(apiMenus[i].isSection) === 'string'
                                    ? Boolean(parseInt(apiMenus[i].isSection))
                                    : (typeof(apiMenus[i].isSection) === 'number' ? Boolean(apiMenus[i].isSection) : apiMenus[i].isSection)

                        if(isSection){
                            navigations[index] = { header: apiMenus[i].title }
                        }
                        else{
                            navigations[index] = apiMenus[i].webIcon !== null
                                                    ? { title: apiMenus[i].title, route: apiMenus[i].webRoute, icon: apiMenus[i].webIcon } 
                                                    : { title: apiMenus[i].title, route: apiMenus[i].webRoute }
                        }
                    }
                    else{
                        const children = getters.extractChildren(apiMenus[i])

                        let isSection = typeof(apiMenus[i].isSection) === 'string'
                                    ? Boolean(parseInt(apiMenus[i].isSection))
                                    : (typeof(apiMenus[i].isSection) === 'number' ? Boolean(apiMenus[i].isSection) : apiMenus[i].isSection)

                        if (isSection) {
                            navigations[index] = { header: apiMenus[i].title }
                            for (let x = 0; x < children.length; x++) {
                                index += 1
                                navigations[index] = children[x]
                            }
                        }
                        else {
                            navigations[index] = apiMenus[i].webIcon !== null
                                                ? { title: apiMenus[i].title, icon: apiMenus[i].webIcon, children }
                                                : { title: apiMenus[i].title, children }
                        }
                    }
                }
            }

            return navigations
        },
    },
    actions: {},
    mutations: {
        UPDATE_API_MENUS(state, val){
            state.apiMenus = val
        }
    }
}